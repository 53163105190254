// Germany
export const locale = {
  lang: "de",
  data: {
    COMMON: {
      CANCEL: "Abbrechen",
      CLOSE: "Schließen",
      SUBMIT: "Einreichen",
      NO: "Nein",
      YES: "Ja",
      YES_CLOSE_AND_LOSE_CHANGES: "Ja, schließen und Änderungen verlieren",
      DISCARD_AND_CLOSE: "Verwerfen und Schließen",
      SAVE_AND_CLOSE: "Speichern und Schließen",
      SEARCH: "Suche...",
      SHOWING_PAGE: "Seite anzeigen",
      OF: "von",
      RESULTS_PER_PAGE: "Ergebnisse pro Seite",
      VALIDATION: {
        SOMETHING_WENT_WRONG:
          "Ups, etwas ist schief gelaufen! Bitte versuche es erneut.",
        SUCCESSFULLY_UPDATED: "Erfolgreich aktualisiert",
        ANY_CHANGES_MADE_WILL_BE_LOST:
          "Alle vorgenommenen Änderungen gehen verloren.",
        ARE_YOU_SURE_YOU_WANT_TO_PROCEED:
          "Bist du sicher, dass du fortfahren möchtest?",
        FAILED_TO_RETRIEVE_DATA:
          "Daten konnten nicht vom Server abgerufen werden",
        FAILED_TO_RETRIEVE_DATA_FOR_SELECTION:
          "Daten für die Auswahl konnten nicht abgerufen werden",
      },
    },
    NAVBAR: {
      HELLO: "Hallo",
      MY_DETAILS: "Meine Details",
      MY_CONTACT_METHODS: "Meine Kontaktmethoden",
      MY_DATA_CONSENTS: "Meine Datenzustimmungen",
      MY_DOCUMENTS: "Meine Dokumente",
      LOGOUT: "Abmelden",
      SELECT_LANGUAGE: "Sprache wählen",
      MENU: "Menü",
      CLOSE: "Schließen",
      SETTINGS: "Einstellungen",
      TERMSANDCONDITIONS: "Geschäftsbedingungen",
      LANGUAGE: {
        ENLABEL: "Englisch",
        CHLABEL: "Chinesisch",
        ESLABEL: "Spanisch",
        JALABEL: "Japanisch",
        DELABEL: "Deutsch",
        FRLABEL: "Französisch",
      },
      MENU_ITEM: {
        CURRENT_PLACEMENT: "Meine aktuelle Platzierung",
        COMPLIANCE: "Meine Compliance",
        TIMESHEETS: "Meine Stundenzettel",
        EXPENSES: "Meine Ausgaben",
        DOCUMENTS: "Meine Dokumente",
      },
    },
    AUTH: {
      LOGIN: {
        BUTTON: {
          SIGN_IN: "Anmelden",
        },
        LABEL: {
          EMAIL: "E-Mail",
          PASSWORD: "Passwort",
          FORGOT_PASSWORD: "Passwort vergessen?",
        },
        VALIDATION: {
          EMAIL_INVALID: "Ungültige E-Mail-Adresse",
          EMAIL_MAX_LENGTH:
            "E-Mail darf nicht länger als {{value}} Zeichen sein",
          EMAIL_REQUIRED: "E-Mail ist erforderlich",
          LOGIN_FAILED: "Die Anmeldedaten sind falsch",
          PASSWORD_MAX_LENGTH:
            "Passwort darf nicht länger als {{value}} Zeichen sein",
          PASSWORD_MIN_LENGTH:
            "Passwort muss mindestens {{value}} Zeichen lang sein",
          PASSWORD_REQUIRED: "Passwort ist erforderlich",
        },
      },
      FORGOT_PASSWORD: {
        ENTER_YOUR_EMAIL_TO_RESET_YOUR_PASSWORD:
          "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen",
        FORGOTTEN_PASSWORD: "Passwort vergessen?",
        BUTTON: {
          OK_GOT_IT: "Ok, verstanden!",
        },
        LABEL: {
          EMAIL: "E-Mail",
          EMAIL_SENT_MESSAGE1:
            "Wir haben eine E-Mail an die von Ihnen angegebene Adresse gesendet, um Ihr Passwort zurückzusetzen.",
          EMAIL_SENT_MESSAGE2:
            "Wenn Sie es nicht erhalten haben, überprüfen Sie bitte Ihren Spam-Ordner und vergewissern Sie sich, dass die eingegebene E-Mail-Adresse korrekt war.",
          EMAIL_SENT_TITLE: "Wiederherstellungs-E-Mail gesendet!",
          SUCCESS: "Ihr Konto wurde erfolgreich zurückgesetzt.",
        },
        VALIDATION: {
          EMAIL_INVALID: "Ungültige E-Mail-Adresse",
          EMAIL_MAX_LENGTH:
            "E-Mail darf nicht länger als {{value}} Zeichen sein",
          EMAIL_REQUIRED: "E-Mail ist erforderlich",
        },
      },
      RESET_PASSWORD: {
        ENTER_NEW_PASSWORD_DETAILS: "Geben Sie neue Passwortdetails ein",
        RESET_PASSWORD: "Passwort zurücksetzen",
        BUTTON: {},
        LABEL: {
          CONFIRM_NEW_PASSWORD: "Neues Passwort bestätigen",
          CREATE_NEW_PASSWORD: "Neues Passwort erstellen",
          OLD_PASSWORD: "Altes Passwort",
          PASSWORD: "Passwort",
        },
        VALIDATION: {
          PASSWORDS_DONT_MATCH:
            "'Passwort' und 'Passwort bestätigen' stimmen nicht überein",
          PASSWORD_MAX_LENGTH:
            "Passwort darf nicht länger als {{value}} Zeichen sein",
          PASSWORD_MIN_LENGTH:
            "Passwort muss mindestens {{value}} Zeichen lang sein",
          PASSWORD_PATTERN:
            "Muss Groß- und Kleinbuchstaben, eine Zahl und eines der folgenden Zeichen enthalten: -+_!@#$%^&*.,?",
          PASSWORD_REQUIRED: "Passwort ist erforderlich",
          REGISTRATION_DETAILS_INCORRECT:
            "Die Registrierungsdetails sind falsch",
        },
      },
    },
    MY_CONTACT_METHODS: {
      MY_CONTACT_METHODS: "Meine Kontakmethoden",
      CONTACT_PREFERENCES: "Kontaktpräferenzen",
      PLEASE_SELECT_HOW_YOU_WISH_TO_BE_CONTACTED:
        "Bitte wählen Sie, wie Sie kontaktiert werden möchten.",
      BUTTON: {},
      LABEL: {
        RECEIVE: "Empfangen",
        DO_NOT_RECEIVE: "Nicht empfangen",
        SALES_EMAIL: "Vertriebs-E-Mail",
        EMAIL: "E-Mail",
        PHONE_CALL: "Anruf",
        TEXT_MESSAGE: "Textnachricht",
      },
      VALIDATION: {
        ERROR_UPDATING:
          "Beim Aktualisieren der Kontaktpräferenzen ist ein Fehler aufgetreten",
      },
    },
    MY_DETAILS: {
      MY_DETAILS: "Meine Details",
      TAB_TITLES: {
        CANDIDATE_INFORMATION: "Kandidateninformation",
        PREFERENCES: "Präferenzen",
        PROFILE: "Profil",
      },
      CANDIDATE_INFORMATION_TAB: {
        FIRST_NAME: "Vorname",
        LAST_NAME: "Nachname",
        MOBILE_PHONE: "Handy",
        HOME_PHONE: "Festnetztelefon",
        WORK_PHONE: "Arbeitstelefon",
        PERSONAL_EMAIL: "Persönliche E-Mail",
        WORK_EMAIL: "Arbeits-E-Mail",
        LINKEDIN: "LinkedIn",
        ADDRESSLINE1: "Adresszeile 1",
        CITY_TOWN: "Stadt",
        POSTCODE: "Postleitzahl",
        COUNTRY: "Land",
      },
      PREFERENCES_TAB: {
        AVAILABILITY: "Verfügbarkeit",
        NOTICE_PERIOD: "Kündigungsfrist",
        CURRENCY: "Währung",
        CONTRACTOR: "Freiberufler",
        TEMP: "Zeitarbeit",
        RATE_FROM: "Tarif ab",
        PAY_FREQUENCY: "Zahlungshäufigkeit",
        RATE_TO: "Tarif bis",
        PERMANENT: "Festanstellung",
        SALARY_FROM: "Gehalt ab",
        SALARY_TO: "Gehalt bis",
        CHOOSE_INTERVAL: "Intervall wählen...",
        SELECT_A_CURRENCY: "Währung auswählen...",
        PAY_FREQUENCY_PLACEHOLDER: "Zahlungsfrequenz auswählen...",
      },
      PROFILE_TAB: {
        CANDIDATE_SUMMARY: "Kandidatenzusammenfassung",
        ROLES_SOUGHT: "Gesuchte Rollen",
        CANDIDATE_SUMMARY_PLACEHOLDER:
          "Bitte geben Sie eine Zusammenfassung des Kandidaten ein...",
        ROLESSOUGHT_SUMMARY_PLACEHOLDER:
          "Bitte geben Sie eine Zusammenfassung der gesuchten Rolle des Kandidaten ein...",
      },
      VALIDATION: {
        ERROR_UPDATING:
          "Beim Aktualisieren Ihrer Daten ist ein Fehler aufgetreten",
        FIRST_NAME_VALIDATION: "Der Vorname ist ein Pflichtfeld",
        LAST_NAME_VALIDATION: "Der Nachname ist ein Pflichtfeld",
        PHONE_NUMBER_VALIDATION:
          "Bitte geben Sie eine gültige Telefonnummer ein",
        PHONE_NUMBER_REQUIRED: "Mobiltelefon ist erforderlich",
        EMAIL_VALIDATION: "Bitte geben Sie eine gültige E-Mail-Adresse ein",
        EMAIL_REQUIRED: "Persönliche E-Mail ist erforderlich",
        FREQUENCY_REQUIRED: "Häufigkeit ist erforderlich",
        RATE_FROM_VALIDATION:
          "Bitte stellen Sie sicher, dass der 'Höchstsatz' größer oder gleich dem 'Mindestsatz' ist",
        CANDIDATE_SUMMARY_VALIDATION:
          "Die maximale Anzahl zulässiger Zeichen beträgt 5.000",
        ROLE_SOUGHT_VALIDATION:
          "Die maximale Anzahl zulässiger Zeichen beträgt 2.000",
        SALARY_TO_VALIDATION:
          "Bitte stellen Sie sicher, dass das 'Höchstgehalt' größer oder gleich dem 'Mindestgehalt' ist",
        SALARY_REQUIRED: "Gehalt erforderlich",
        PAY_FREQUENCY_REQUIRED: "Zahlungsfrequenz erforderlich",
        SELECT_A_UNIT: "Bitte wählen Sie eine Einheit",
        RATE_SET_REQUIRED: "Erforderlich, wenn ein Tarif festgelegt wird",
        SALARY_SET_REQUIRED: "Erforderlich, wenn ein Gehalt festgelegt wird",
        NOTICE_PERIOD_VALIDATION:
          "Die Kündigungsfrist darf nicht weniger als 0 sein",
      },
    },
    MY_DATA_CONSENTS: {
      MY_DATA_CONSENTS: "Meine Datenzustimmungen",
      PLEASE_SELECT_HOW_YOU_WISH_TO_MANAGE_YOUR_DATA_CONSENTS:
        "Bitte wählen Sie aus, wie Sie Ihre Datenzustimmungen verwalten möchten.",
      BUTTON: {},
      LABEL: {
        CONSENT: "Zustimmung",
        WITHDRAW: "Widerrufen",
        DATA_CONSENT_PURPOSE: "Zweck der Datenzustimmung",
        CONSENT_METHOD: "Zustimmungsmethode",
        CONSENT_GIVEN: "Zustimmung erteilt",
        CONSENT_EXPIRY: "Zustimmungsablauf",
        WITHDRAW_CONSENT: "Zustimmung widerrufen",
      },
      VALIDATION: {
        ERROR_UPDATING:
          "Beim Aktualisieren meiner Datenzustimmungen ist ein Fehler aufgetreten",
      },
    },
    MY_CURRENT_PLACEMENT: {
      MY_CURRENT_PLACEMENTS: "Meine aktuellen Platzierungen",
      BUTTON: {},
      LABEL: {
        NO_ACTIVE_PLACEMENTS: "Keine aktiven Platzierungen",
        CLIENT: "Kunde",
        PLACEMENT_DETAILS: "Platzierungsdetails",
        DOCUMENTS: "Dokumente",
        JOB_TITLE: "Jobtitel",
        TYPE: "Typ",
        START_DATE: "Startdatum",
        END_DATE: "Enddatum",
        CANDIDATE_COMPANY: "Kandidatenfirma",
        NOTICE_FROM_US: "Benachrichtigung von uns",
        NOTICE_FROM_YOU: "Benachrichtigung von Ihnen",
        PAY: "Bezahlung",
        CLIENT_ADDRESS: "Kundenadresse",
        COMPLIANCE: "Konformität",
        STATUS: "Status",
        EVIDENCE: "Nachweis",
        REASON_FOR_REJECTION: "Ablehnungsgrund",
        VALID_FROM: "Gültig ab",
        VALID_TO: "Gültig bis",
        DESCRIPTION: "Beschreibung",
        LAST_UPDATED: "Zuletzt aktualisiert",
        DOCUMENT: "Dokument",
        PLEASE_CLICK_TO_DOWNLOAD: "Bitte klicken Sie zum Herunterladen",
        DOWNLOAD: "Herunterladen",
        NO_DOCUMENTS_FOUND: "Keine Dokumente gefunden",
        CHECKED_ON: "Überprüft am",
        CHECKED_BY: "Überprüft von",
        OUTSTANDING: "Ausstehend",
        SUBMITTED: "Einreichung",
        CHECKED: "Überprüft",
        REJECTED: "Abgelehnt",
      },
      VALIDATION: {
        FROM_DATE_IS_REQUIRED: "Das Startdatum ist erforderlich",
        TO_DATE_IS_REQUIRED: "Das Enddatum ist erforderlich",
        TO_DATE_LESS_THAN_FROM_DATE:
          "Das Enddatum muss nach dem Startdatum liegen",
        FROM_DATE_GREATER_THAN_TO_DATE:
          "Das Startdatum darf nicht nach dem Enddatum liegen",
        ERROR_SAVING: "Fehler beim Speichern der Konformität",
        TO_DATE_MUST_BE_IN_FUTURE: "Das Enddatum muss in der Zukunft liegen",
      },
    },
    FILEUPLOAD: {
      CHOOSEFILE: "Datei auswählen",
      UPLOADFILE: "Datei hochladen",
      REMOVEFILE: "Datei entfernen",
      PENDINGUPLOAD: "Ausstehender Upload",
      COMPLETEDUPLOAD: "Upload abgeschlossen",
      COMPLETED: "Abgeschlossen",
      VALIDATION: {
        INVALID_FILE: "Ungültige Datei ausgewählt",
        FILE_IS_ZERO_SIZE:
          "Die Dateigröße darf nicht null sein. Bitte wählen Sie eine gültige Datei aus und versuchen Sie es erneut.",
        MULTIPLE_FILES_ARE_ZERO_SIZE:
          "Die Dateigrößen dürfen nicht null sein. Ungültige Dateien werden entfernt. Bitte stellen Sie sicher, dass alle ausgewählten Dateien gültig sind, und versuchen Sie es erneut.",
      },
    },
    DOCUSIGN: {
      DOCUSIGN_TITLE: "DocuSign",
      LABEL: {
        COMPLETED: "Abgeschlossen",
        SENT: "Gesendet",
        DELIVERED: "Geliefert",
        VOIDED: "Ungültig",
        CREATED: "Erstellt",
        STATUS: "Status",
        SUBJECT: "Betreff",
        PLACEMENT: "Platzierung",
        CREATED_DATE: "Erstellungsdatum",
        NAME: "Name",
        TYPE: "Typ",
        DOCUMENTID: "Dokumenten-ID",
        SIGN_DOCUMENTS: "Dokumente unterschreiben",
        EMBEDDED_URL_CREATED: "Eingebettete URL erstellt",
        VIEW_PDF: "PDF anzeigen",
      },
    },
  },
};
